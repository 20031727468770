<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        ข้อมูลการซ่อมบำรุง
      </v-toolbar-title>
      <v-spacer/>
      <v-btn v-if="$store.state.authUser.user_permissions.includes('delete_maintenance')"
             @click="showDeleteMaintenanceDialog = true" depressed color="error">ลบรายการซ่อมบำรุงนี้
      </v-btn>
    </v-toolbar>
    <div class="mx-4 pb-4 box box-2" style="min-height: 100%;">
      <v-card height="100%">
        <v-col
            cols="12"
            md="10"
            lg="8"
            xl="6"
            style="height: 100%"
        >
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >
            <v-row>
              <v-col cols="4" align-self="center">รถที่ซ่อม</v-col>
              <v-col cols="8">
                <v-select
                    disabled
                    outlined
                    dense
                    height="32px"
                    class="rounded mt-3"
                    hide-details="auto"
                    :value="vehicle_id"
                    :items="vehicleOption"
                    placeholder="ไม่ได้เลือกรถ"
                    item-text="name"
                    item-value="id"
                    :clearable="true"
                >
                  <template slot="selection" slot-scope="data">
                    <div style="display: flex;width: 100%;justify-content: space-between">
                      <span>{{ data.item.name }}</span>
                      <span><span style="color: #265a88">ทะเบียน {{ data.item.licence }}</span></span>
                    </div>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" align-self="center">วันที่</v-col>
              <v-col cols="8">
                <DatePicker disabled v-model="date" default-today/>
              </v-col>
            </v-row>

            <v-row class="mt-4">
              <v-col align-self="center">
                <span class="mr-2">รายการซ่อม ({{ items.length }})</span>
                <v-icon v-if="$store.state.authUser.user_permissions.includes('change_maintenance')"
                        @click="showCreateDialog = true" color="success">mdi-plus-circle
                </v-icon>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-data-table
                    :headers="itemHeaders"
                    :items="items"
                    disable-sort
                    hide-default-footer
                >
                  <template #item.no="{item}">
                    <td>
                      {{ items.indexOf(item) + 1 }}
                    </td>
                  </template>

                  <template #item.totalPrice="{ item }">
                    {{ (item.quantity * item.price).toFixed(2) }}
                  </template>

                  <template v-if="$store.state.authUser.user_permissions.includes('change_maintenance')"
                            #item.action="{ item }" class="px-0">
                    <v-icon @click="showDeleteDialog = true;willDeleteItem = item" color="error">mdi-close-circle
                    </v-icon>
                  </template>

                  <template slot="body.append">
                    <tr style="background-color: #eaf8ff">
                      <td>
                        ค่าซ่อมรวม
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td class="text-center"><b>{{ $currency(sumTotalPrice).format() }}</b></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>

            <v-divider/>

            <v-row align="start" class="mt-10">
              <v-col cols="4" align-self="center">หมายเหตุเพิ่มเติม</v-col>
              <v-col cols="8">
                <v-textarea v-model="remark" outlined hide-details>

                </v-textarea>
              </v-col>
            </v-row>
          </v-form>

          <v-row class="mt-4">
            <v-spacer/>
            <v-btn v-if="$store.state.authUser.user_permissions.includes('change_maintenance')" @click="saveRemark()"
                   depressed color="success" class="mr-3">
              <v-icon left>mdi-content-save</v-icon>
              บันทึก
            </v-btn>
          </v-row>
          <!--          <v-row class="mt-8">-->
          <!--            <v-btn text>ยกเลิก</v-btn>-->
          <!--            <v-btn @click="saveToDatabase()" depressed color="success">-->
          <!--              <v-icon left>mdi-content-save</v-icon>-->
          <!--              บันทึก-->
          <!--            </v-btn>-->
          <!--          </v-row>-->
        </v-col>
      </v-card>
    </div>


    <v-dialog v-model="showCreateDialog" persistent max-width="625">
      <v-card>
        <v-card-title class="text-h5" style="font-family: Kanit,serif !important;">เพิ่มรายการอะไหล่</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              อะไหล่
            </v-col>
            <v-col>
              <!--              <v-text-field hide-details="auto" outlined height="32px" v-model="newPart"></v-text-field>-->
              <v-combobox
                  hide-selected
                  v-model="newPart"
                  :rules="[rules.required]"
                  height="32px"
                  hide-details="auto"
                  :items="part"
                  outlined
                  clearable
                  dense
              ></v-combobox>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              ประเภท
            </v-col>
            <v-col>
              <v-select :items="typeOption"
                        v-model="newType"
                        item-value="name"
                        outlined
                        hide-details
                        dense
                        height="32px"
                        class="rounded"></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              จำนวน
            </v-col>
            <v-col>
              <v-text-field hide-details="auto" type="number" outlined height="32px"
                            v-model="newQuantity"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              ราคา
            </v-col>
            <v-col>
              <v-text-field hide-details="auto" type="number" outlined height="32px" v-model="newPrice"
                            suffix="บาท"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>ราคารวม</v-col>
            <v-col>{{ (newPrice * newQuantity).toFixed(2) }}</v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="showCreateDialog = false" depressed text> ยกเลิก</v-btn>
          <v-btn depressed color="success" @click="addItem()">
            <v-icon left>mdi-content-save</v-icon>
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDeleteDialog" persistent max-width="625">
      <v-card>
        <v-card-title class="text-h5" style="font-family: Kanit,serif !important;">ลบรายการอะไหล่ชิ้นนี้</v-card-title>
        <v-card-text>คุณต้องการลบอะไหล่ชิ้นนี้หรือไม่ การกระทำดังกล่าวไม่สามารถกูคืนได้</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="showDeleteDialog=false" text>ยกเลิก</v-btn>
          <v-btn @click="removeItem" depressed color="error">
            <v-icon left>mdi-delete</v-icon>
            ลบรายการนี้
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDeleteMaintenanceDialog" persistent max-width="625">
      <v-card>
        <v-card-title class="text-h5" style="font-family: Kanit,serif !important;">ลบรายการซ่อมบำรุง</v-card-title>
        <v-card-text>คุณต้องการลบรายการซ่อมบำรุงนี้หรือไม่ การกระทำดังกล่าวไม่สามารถกูคืนได้</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="showDeleteMaintenanceDialog=false" text>ยกเลิก</v-btn>
          <v-btn @click="deleteMaintenance" depressed color="error">
            <v-icon left>mdi-delete</v-icon>
            ลบรายการนี้
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
import DatePicker from "@/components/DatePicker";

export default {
  components: {DatePicker},
  name: "CreateMaintenance",
  data() {
    return {
      rules: {
        required: value => value.length > 0 || "ต้องกรอก",
        required_select: value => !!value || "เลือกหมวดสินค้า",
        min: value => value >= 0 || "จำนวนต้องเป็นตั้งแต่ 0 ขึ้นไป"
      },
      vehicleOption: [],
      typeOption: ['ซ่อม', 'ซื้อใหม่'],
      vehicle_id: '',
      date: '',
      remark: '',
      items: [],
      valid: true,
      itemHeaders: [
        {
          text: 'ลำดับที่',
          value: 'no',
          align: 'center',
          width: '80'
        },
        {
          text: 'อะไหล่',
          value: 'part.name',
          align: 'left',
        },
        {
          text: 'ประเภท',
          value: 'type',
          align: 'center',
          width: '120'
        },
        {
          text: 'จำนวน',
          value: 'quantity',
          align: 'center',
          width: '150'
        },
        {
          text: 'ราคา',
          value: 'price',
          align: 'center',
          width: '180'
        },
        {
          text: 'ราคารวม',
          value: 'totalPrice',
          align: 'center',
          width: '180'
        },
        {
          text: '',
          value: 'action',
          align: 'center',
          class: 'px-0',
          width: '60'
        },
      ],
      maintenanceData: {},
      showCreateDialog: false,
      showDeleteDialog: false,
      showDeleteMaintenanceDialog: false,
      newPart: '',
      newType: '',
      newQuantity: 1,
      newPrice: 0,
      willDeleteItem: {},
      part: [],
      sumTotalPrice: 0
    }
  },
  created() {
    this.getVehicle()
    this.getMaintenanceDetail()
  },
  methods: {
    getMaintenanceDetail() {
      axios.get(`/api/tracks/maintenance/${this.$route.params.id}/`).then(res => {
        this.maintenanceData = res.data.data
        this.vehicle_id = this.maintenanceData.vehicle ? this.maintenanceData.vehicle.id : ''
        this.date = this.maintenanceData.date
        this.remark = this.maintenanceData.remark
        this.items = this.maintenanceData.maintenance_item
        this.items.forEach(m => {
          this.sumTotalPrice += (parseFloat(m.price) * parseFloat(m.quantity))
        })
      }).catch(e => {
        this.$store.state.snackbar = {
          text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
          visible: true,
          color: 'error'
        }
      })
    },
    getVehicle() {
      axios.get('/api/stocks/vehicle/รถบรรทุก/')
          .then(res => {
            this.vehicleOption = res.data.data
          })
          .catch(e => {
            console.log(e)
          })
    },
    getPart() {
      if (!this.part.length) {
        axios.get('/api/tracks/part/').then(res => {
          // console.log(res.data.data)
          // this.part = res.data.data
          res.data.data.forEach(p => {
            this.part.push(p.name)
          })
          console.log(this.part)
        }).catch(e => {
          console.error(e)
        })
      }
    },
    addItem() {
      let payload = {
        maintenance_id: this.$route.params.id,
        part: this.newPart,
        type: this.newType,
        quantity: this.newQuantity,
        price: this.newPrice
      }
      axios.post('/api/tracks/maintenance_item/', payload).then(() => {
        this.getMaintenanceDetail()
        this.showCreateDialog = false
        this.$store.state.snackbar = {
          text: 'เพิ่มข้อมูลสำเร็จ',
          visible: true,
          color: 'success'
        }
        this.newPart = ''
        this.newType = ''
        this.newQuantity = 1
        this.newPrice = 0
      }).catch(e => {
        this.$store.state.snackbar = {
          text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
          visible: true,
          color: 'error'
        }
      })
    },
    removeItem() {
      // if (this.items.length > 1)
      //   this.items.splice(this.items.indexOf(index), 1)
      console.log(this.willDeleteItem)
      axios.delete(`api/tracks/maintenance_item/${this.willDeleteItem.id}/`).then(() => {
        this.items.splice(this.items.indexOf(this.willDeleteItem), 1)
        this.$store.state.snackbar = {
          text: 'ลบรายการสำเร็จ',
          visible: true,
          color: 'success'
        }
        this.showDeleteDialog = false
      }).catch(e => {
        this.$store.state.snackbar = {
          text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
          visible: true,
          color: 'error'
        }
      })
    },
    saveRemark() {
      let payload = {
        id: this.$route.params.id,
        remark: this.remark
      }
      axios.put('/api/tracks/maintenance/', payload).then(() => {
        this.$store.state.snackbar = {
          text: "บันทึกหมายเหตุสำเร็จ",
          color: 'success',
          visible: true
        }
        this.$router.go(-1)
      })
    },
    deleteMaintenance() {
      axios.delete(`/api/tracks/maintenance/${this.$route.params.id}/`).then(() => {
        this.$store.state.snackbar = {
          text: "ลบรายการซ่อมบำรุงสำเร็จ",
          color: 'success',
          visible: true
        }
        this.$router.push({path: '/maintenance'})
      })
    }
    // async saveToDatabase() {
    //   await this.$refs.form.validate()
    //   if (this.valid) {
    //     this.items.map(item => {
    //       item.price = parseFloat(item.price)
    //       item.quantity = parseFloat(item.quantity)
    //       return item
    //     })
    //     let payload = {
    //       vehicle_id: this.vehicle_id,
    //       date: this.date,
    //       remark: this.remark,
    //       items: this.items
    //     }
    //     console.log(payload)
    //     axios.post('/api/tracks/maintenance/', payload)
    //         .then(res => {
    //           console.log(res.data)
    //           this.$store.state.snackbar = {
    //             text: 'เพิ่มข้อมูลการซ่อมบำรุงสำเร็จ',
    //             visible: true,
    //             color: 'success'
    //           }
    //           this.$router.push({path: '/maintenance'})
    //         })
    //         .catch(e => {
    //           console.log(e)
    //         })
    //   }
    //
    // }
  },
  watch: {
    showCreateDialog(value) {
      if (value) {
        this.getPart()
      }
    }
  }
}
</script>

<style scoped>

</style>